<template>
  <div style="max-width: 480px; margin: auto">
    <v-card flat class="p-b-20 p-t-10">
      <v-btn @click="closeVideo" icon style="position: absolute; top: 0px; right: 0px">
        <v-icon>close</v-icon>
      </v-btn>
      <v-card-text align="center" class="bold-f f-s-24">
        {{ provider.name }}
      </v-card-text>
      <v-card-text>
        A type of <span class="bold-f">Instant Bank Verification</span>
      </v-card-text>
      <v-card-text>After selecting
        <v-btn color="primary" small>next</v-btn>
        you will be directed to the {{ provider.name }} web page.
      </v-card-text>
      <v-card-text>{{ provider.name }} is a secured, private and encrypted service.</v-card-text>
      <v-card-text>It allows us to view a <b>read-only copy</b> of your bank statement which includes your recent Direct
        Deposits from your employer.
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  name: 'decision-logic',
  props: {
    data: Object,
    provider: Object
  },
  computed: {},
  created () {
  },
  methods: {
    closeVideo () {
      this.data.show = false
    }
  }
}
</script>
