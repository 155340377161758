import { DataType } from '../core'

const PROFILE_SECTION_LAYOUT_SETTING = {
  EMPLOYMENT: {
    formFields: [
      { label: 'Employer Name', fieldKey: 'employerName' },
      { label: 'Job Title', fieldKey: 'jobTitle' },
      {
        label: 'Payday on Holiday',
        fieldKey: 'paydayOnHoliday',
        controlType: 'v-select',
        options: { paydayOnHoliday: DataType.PaydayOnHolidayType }
      },
      { label: 'Work Phone', fieldKey: 'employerPhone', roles: 'phone', mask: 'phone' }
    ]
  },
  PERSONAL: {
    formFields: [
      { label: 'First Name', fieldKey: 'firstName' },
      { label: 'Middle Name', fieldKey: 'middleName' },
      { label: 'Last Name', fieldKey: 'lastName' },
      { label: 'Address', fieldKey: 'address' },
      { label: 'City', fieldKey: 'city' },
      { label: 'State', fieldKey: 'state', options: { state: DataType.State } },
      { label: 'Zip', fieldKey: 'zip' },
      { label: 'Full SSN', fieldKey: 'ssn' },
      { label: 'Date of Birth', fieldKey: 'birthDate' },
      { label: 'Email', fieldKey: 'email' }
    ]
  }
}
export { PROFILE_SECTION_LAYOUT_SETTING }
